import {
	ConnectWalletIconsw1,
	ConnectWalletIconsw2,
	ConnectWalletIconsw3,

} from '../../helpers/allImgs'

export const whoWeData = [
	{img:ConnectWalletIconsw1,text:'Included NFT Minting with IPFS'},
	{img:ConnectWalletIconsw2,text:'Powerful Samart Contract with Solidity'},
	{img:ConnectWalletIconsw3,text:'Buy and Sell NFTs With ETH'}
]