import { useContext  } from 'react';

import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import Spinner from '../Layout/Spinner';

import MintSection from "./MintSection"
import NFTCollection from './NFTCollection/NFTCollection';

import '../../assets/css/general.css';
const Main = () => {
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  
  return(
    <>
      <div>
        {!collectionCtx.nftIsLoading && (
          <MintSection
              SecClass="special-mr section-padding-100"
              topTitle="민트 신규 NFT 아이템 만들기"
              downTitle="지금 NFT를 만들고 판매용 크리에이티브 NFT 디지털 자산을 올려보세요."
              topText="메타마스크를 통한 간편한 지갑연동으로 로그인하여 스마트계약서를 통해 NFT를 구매하고 판매할 수 있습니다. 자유롭게 창작물을 올려 수익을 올려보세요 누구나 할수 있습니다!"
              downText="메타마스크를 통한 간편한 지갑연동으로 로그인하여 스마트계약서를 통해 NFT를 구매하고 판매할 수 있습니다. "
          />
        )}
        {collectionCtx.nftIsLoading && <Spinner />}
      </div>

      {!marketplaceCtx.mktIsLoading && <NFTCollection ClassTextDIV="top-sec-h" />}
      {marketplaceCtx.mktIsLoading && <Spinner />}
        
    </>
  );
};

export default Main;