import {
    OurServiceIconsS1,
    OurServiceIconsS2,
    OurServiceIconsS3,
    OurServiceIconsS4,
} from '../../helpers/allImgs'

const OurServiceContent = [
    {
        img:OurServiceIconsS1,
        title:"지갑 설정",
        text:"메타마스크 지갑을 필요한 ETH 네트워크에 연결해야 합니다"
    },
    {
        img:OurServiceIconsS2,
        title:"NFT 생성",
        text:" IPFS를 사용하여 NFT 자산을 발행하고 nft 정보를 업로드하고 발행 버튼을 클릭하면 민팅이 됩니다"
    },
    {
        img:OurServiceIconsS3,
        title:"NFT 아이템",
        text:"NFT를 발행하면 메타마스크 지갑이 팝업되어 발행 기능을 확인하고 확인후 nft가 등록됩니다."
    },
    {
        img:OurServiceIconsS4,
        title:"NFT 구매 및 판매",
        text:"등록되면 이제 NFT를 판매 및 구매할 준비가 되었습니다. NFT 가격을 지정하고 제안을 클릭하기만 하면 됩니다."
    }
]

export default OurServiceContent