import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";

import Web3Provider from "./store/Web3Provider";
import CollectionProvider from "./store/CollectionProvider";
import MarketplaceProvider from "./store/MarketplaceProvider";
import Nav from "./Nav";
import FooterPages from "../src/components/Content/FooterPages";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "./pages/home";
import ConnectWalletPage from "./pages/connectWallet";
import ExplorePage from "./pages/explore";
import CreateMintPage from "./pages/createMint";

ReactDOM.render(
  <Web3Provider>
    <CollectionProvider>
      <MarketplaceProvider>
        <BrowserRouter>
          <Nav />

          <Switch>
            <Route path="/" exact={true}>
              <HomePage />
            </Route>
            <Route path="/explore" exact={true}>
              <ExplorePage />
            </Route>
            <Route path="/mint-nft" exact={true}>
              <CreateMintPage />
            </Route>
            <Route path="/connect-wallet" exact={true}>
              <ConnectWalletPage />
            </Route>
          </Switch>

          <FooterPages
            mt0={true}
            text="메타마스크를 통한 간편한 지갑연동으로 로그인하여 스마트계약서를 통해 NFT를 구매하고 판매할 수 있습니다. 자유롭게 창작물을 올려 수익을 올려보세요 누구나 할수 있습니다!"
          />
        </BrowserRouter>
      </MarketplaceProvider>
    </CollectionProvider>
  </Web3Provider>,
  document.getElementById("root")
);
