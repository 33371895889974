import React from "react";
import WelcomeArea from "../../components/Content/WelcomeArea";
import OurService from "../../components/Content/OurService";
import ConnectWallet from "../../components/Content/ConnectWallet";
import App from "../../App";

const HomePage = () => {
  return (
    <>
      <WelcomeArea
        ClassSec="hero-section ai5 relative"
        welcomeContClass="welcome-content demo1 text-left"
        specialHeadClass="special-head gradient-text"
        text="스마트 계약을 통한 NFT 마켓플레이스"
        ClassTitle="bold"
        title="간편한 방법으로 NFT를 사고 팔수 있습니다 test"
        ClassDummyText="w-text"
        dummyText="메타마스크를 통한 간편한 지갑연동으로 로그인하여 스마트계약서를 통해 NFT를 구매하고 판매할 수 있습니다. 자유롭게 창작물을 올려 수익을 올려보세요 누구나 할수 있습니다!"
        addRow={true}
      />

      <OurService
        ClassSec="our_services_area section-padding-100"
        titleTop="플렛폼 이용방법"
        titleDown="쉬운단계부터 시작하기"
        text="메타마스크를 통한 간편한 지갑연동으로 로그인하여 스마트계약서를 통해 NFT를 구매하고 판매할 수 있습니다. 자유롭게 창작물을 올려 수익을 올려보세요 누구나 할수 있습니다!"
      />

      <App />

      <ConnectWallet />
    </>
  );
};

export default HomePage;
